<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">新增/修改 配置</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>归类</b></label>
            <el-select v-model="model.NAME" style="display: block">
              <el-option value="MEET_LOC" label="会议地点" />
              <el-option value="MEET_CAT" label="会议分类" />
            </el-select>
          </div>
          <div class="form-group">
            <label><b>可选项</b></label>
            <el-input type="text" v-model="model.VALUE"/>
          </div>
          <div class="form-group">
            <label><b>排序号</b></label>
            <el-input type="text" v-model="model.SORT"/>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dict_edit",
  data(){
    return{
      show:false,
      model: {},
      mi:{TYPE:'MEET',NAME:'MEET_LOC',SORT:1},
    }
  },
  methods:{
    init(model){
      this.show=true;
      if (model==undefined || model==null) this.model=Object.assign({},this.mi);
      else {
        this.model=JSON.parse((JSON.stringify(model)));
      }
    },
    submit(){
      let self=this;
      if (this.model.NAME=="MEET_LOC") this.model.DESCRIPTION="会议地点";
      else if (this.model.NAME=="MEET_CAT") this.model.DESCRIPTION="会议分类";
      this.whale.remote.getResult({
        url:"/api/School/FX/DictApi/Save",
        data:self.model,
        completed:function (){
          self.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
